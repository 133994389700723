import React from "react";

import Image from "../../atoms/Image/Image";
import ParseText from "../../atoms/ParseText/ParseText";

import { StyledText } from "../../atoms/StyledText/StyledText";
import {
  StyledOfferSecondSection,
  StyledLeftBottomWrapper,
  StyledBottomText,
  StyledLeftWrapper,
  StyledContent,
  StyledBottomRightWrapper,
  StyledRightWrapper,
} from "./StyledOfferSecondSection";

const OfferSecondSection = ({
  title,
  desc1,
  image1,
  title1,
  desc2,
  image2,
  title2,
  desc3,
  image3,
}) => {
  return (
    <>
      <StyledOfferSecondSection>
        <StyledLeftWrapper>
          <StyledContent>
            <StyledText
              hasdeclaredfontweight="700"
              hasdeclaredfontsize="clamp(24px, 2.092vw, 28px)"
            >
              {title1}
            </StyledText>
            <Image image={image1} />
            <ParseText text={desc1} />
          </StyledContent>
        </StyledLeftWrapper>
        <StyledRightWrapper>
          <StyledContent>
            <StyledText
              hasdeclaredfontweight="700"
              hasdeclaredfontsize="clamp(24px, 2.092vw, 28px)"
            >
              {title2}
            </StyledText>
            <Image image={image2} />
            <ParseText text={desc2} />
          </StyledContent>
        </StyledRightWrapper>
      </StyledOfferSecondSection>
      <StyledBottomText>
        <StyledLeftBottomWrapper>
          <StyledText
            hasdeclaredfontweight="700"
            hasdeclaredfontsize="clamp(24px, 2.092vw, 28px)"
          >
            {title}
          </StyledText>
          <ParseText text={desc3} />
        </StyledLeftBottomWrapper>
        <StyledBottomRightWrapper>
          <Image image={image3} />
        </StyledBottomRightWrapper>
      </StyledBottomText>
    </>
  );
};

export default OfferSecondSection;
