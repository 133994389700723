/** @format */

import styled from "styled-components";
import { Link } from "gatsby";

export const StyledRealizationCard = styled(Link)`
  width: ${({ fullwidth }) => (fullwidth ? "100%" : "40%")};
  max-width: 400px;
  min-width: 280px;
  border-radius: 50px 50px 0 0;
  overflow: hidden;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background-color: #fff;
  color: #000;
  text-decoration: none;
  padding-bottom: 10px;

  > div {
    &:first-child {
      img {
        transition: transform 250ms linear;
      }
    }
  }

  &:hover {
    > div {
      &:first-child {
        img {
          transform: scale(1.05);
        }
      }
    }
  }

  &:focus-visible {
    outline-width: 1px;
    outline-style: solid;
    outline-color: #0bc76d;
    outline-offset: 4px;
  }

  @media only screen and (max-width: 357px) {
    width: 100%;
    min-width: unset;
  }
`;

export const StyledContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: clamp(14px, 1.042vw, 20px);
  padding: clamp(8px, 1.146vw, 22px) clamp(16px, 1.146vw, 22px);
`;

export const StyledImage = styled.div`
  height: clamp(160px, 12.5vw, 240px);

  .gatsby-image-wrapper {
    height: 100%;
  }
`;
