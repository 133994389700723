import styled from "styled-components";

export const StyledOpinionCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media only screen and (max-width: 768px){
    height: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledTextWrapper = styled.div`
  font: 400 clamp(15px, 1.102vw, 20px)/1.35em Poppins;

  @media only screen and (max-width: 768px){
    text-align: center;
  }
`;

export const StyledAuthor = styled.div`
  font-size: 14px;
  font-style: italic;
  font-weight: 700;

  @media only screen and (max-width: 768px){
    margin-top: 24px;
    text-align: center;
  }
`;
