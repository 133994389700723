/** @format */

import React from "react";

import Image from "../../atoms/Image/Image";
import Button from "../../atoms/Button/Button";

import {
  StyledRealizationCard,
  StyledImage,
  StyledContentWrapper,
} from "./StyledRealizationCard";
import { StyledText } from "../../atoms/StyledText/StyledText";

const RealizationCard = ({ image, local, size, surface, slug, fullWidth }) => {
  return (
    <StyledRealizationCard
      fullwidth={fullWidth ? fullWidth.toString() : undefined}
      to={`/realizacje/${slug}`}
    >
      <StyledImage>
        <Image image={image} />
      </StyledImage>
      <StyledContentWrapper>
        <StyledText
          hasdeclaredfontcolor="#000"
          hasdeclaredfontsize="clamp(14px, 1.038vw, 18px)"
          hasdeclaredlineheight="1.35em"
          hasdeclaredfontweight="700"
        >
          Lokalizacja:{" "}
          <span style={{ color: "#000", fontWeight: "normal" }}>{local}</span>
        </StyledText>
        <StyledText
          hasdeclaredfontcolor="#000"
          hasdeclaredfontsize="clamp(14px, 1.038vw, 18px)"
          hasdeclaredlineheight="1.35em"
          hasdeclaredfontweight="700"
        >
          Rozmiar:{" "}
          <span style={{ color: "#000", fontWeight: "normal" }}>{size}</span>
        </StyledText>
        <StyledText
          hasdeclaredfontcolor="#000"
          hasdeclaredfontsize="clamp(14px, 1.038vw, 18px)"
          hasdeclaredlineheight="1.35em"
          hasdeclaredfontweight="700"
        >
          Powierzchnia:{" "}
          <span style={{ color: "#000", fontWeight: "normal" }}>{surface}</span>
        </StyledText>
        <Button
          text={"ZOBCZ WIĘCEJ"}
          variant="white"
          className="realization-card-btn"
          tabIndex="-1"
        />
      </StyledContentWrapper>
    </StyledRealizationCard>
  );
};

export default RealizationCard;
