/** @format */

import React from "react";
import { graphql } from "gatsby";

import Banner from "../components/molecules/Banner/Banner";
import Container from "../components/atoms/Container/Container";
import OfferSecondSection from "../components/organisms/OfferSecondSection/OfferSecondSection";
import Opinions from "../components/organisms/Opinions/Opinions";
import OurRealizations from "../components/organisms/OurRealizations/OurRealizations";
import HelmetTemplate from "../components/molecules/HelmetTemplate/HelmetTemplate";

const Offer = ({
  data: {
    datoCmsOfertaStrona: {
      tytu,
      duEZdjCieNaPoczTkuStrony,
      haleNamiotoweOpis,
      haleNamiotoweTytul,
      haleNamiotoweZdjCie,
      haleStaloweOpis,
      haleStaloweTytul,
      haleStaloweZdjecie,
      konstrukcjeStaloweOpis,
      konstrukcjeStaloweZdjCie,
      konstrukcjeStaloweTytul,
    },
  },
}) => {
  return (
    <main>
      <HelmetTemplate
        title="Oferta"
        desc="Oferta firmy Kon-stal, tworzymy i budujemy hale namiotowe, hale stalowe oraz wszelkiego rodzaju konstrukcje stalowe"
      />
      <Container>
        <Banner image={duEZdjCieNaPoczTkuStrony} text={tytu} />
        <OfferSecondSection
          image3={haleNamiotoweZdjCie}
          title={haleNamiotoweTytul}
          desc3={haleNamiotoweOpis}
          title2={haleStaloweTytul}
          image2={haleStaloweZdjecie}
          desc2={haleStaloweOpis}
          title1={konstrukcjeStaloweTytul}
          desc1={konstrukcjeStaloweOpis}
          image1={konstrukcjeStaloweZdjCie}
        />
        <Opinions />
        <OurRealizations />
      </Container>
    </main>
  );
};

export default Offer;

export const query = graphql`
  query offerPageQuery {
    datoCmsOfertaStrona {
      tytu
      konstrukcjeStaloweZdjCie {
        alt
        title
        gatsbyImageData
      }
      konstrukcjeStaloweTytul
      konstrukcjeStaloweOpis
      haleStaloweZdjecie {
        alt
        title
        gatsbyImageData
      }
      haleStaloweTytul
      haleStaloweOpis
      haleNamiotoweTytul
      haleNamiotoweOpis
      haleNamiotoweZdjCie {
        alt
        title
        gatsbyImageData
      }
      duEZdjCieNaPoczTkuStrony {
        alt
        title
        gatsbyImageData
      }
    }
  }
`;
