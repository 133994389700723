/** @format */

import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import RealizationCard from "../../molecules/RealizationCard/RealizationCard";
import Button from "../../atoms/Button/Button";

import { StyledOurRealizations, StyledContent } from "./StyledOurRealizations";
import { StyledText } from "../../atoms/StyledText/StyledText";

const OurRealizations = ({ centerText, weightText }) => {
  const {
    datoCmsNaszeRealizacjeSekcja: { tekstPrzycisku, tytu },
    allDatoCmsRealizacja: { nodes },
  } = useStaticQuery(graphql`
    query ourRealizationsQuery {
      datoCmsNaszeRealizacjeSekcja {
        tekstPrzycisku
        tytu
      }
      allDatoCmsRealizacja {
        nodes {
          miniaturka {
            alt
            title
            gatsbyImageData
          }
          meta {
            publishedAt
          }
          lokalizacja
          powierzchnia
          rozmiar
          slug
        }
      }
    }
  `);
  return (
    <StyledOurRealizations>
      <StyledText
        hasdeclaredfontsize="clamp(22px,2.292vw,44px)"
        hasdeclaredpadding="0 0 clamp(22px, 1.979vw, 38px) 0"
        hasdeclaredtextalign={centerText ? "center" : "left"}
        hasdeclaredfontweight="700"
        hasdeclaredtexttransform={weightText ? "uppercase" : "normal"}
      >
        {tytu}
      </StyledText>
      <StyledContent>
        {nodes
          .sort((a, b) => {
            const aDate = new Date(a.meta.publishedAt);
            const bDate = new Date(b.meta.publishedAt);

            return bDate - aDate;
          })
          .map((e, index) =>
            index < 3 ? (
              <RealizationCard
                image={e.miniaturka}
                local={e.lokalizacja}
                size={e.rozmiar}
                slug={e.slug}
                surface={e.powierzchnia}
                key={e.slug}
              />
            ) : null
          )}
      </StyledContent>
      <Button
        where={"/realizacje"}
        text={tekstPrzycisku}
        className="home-third-section"
      />
    </StyledOurRealizations>
  );
};

export default OurRealizations;
