/** @format */

import React, { useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import OpinionCard from "../../molecules/OpinionCard/OpinionCard";

import { StyledOpinions } from "./StyledOpinions";
import { StyledText } from "../../atoms/StyledText/StyledText";

const Opinions = () => {
  const {
    datoCmsCoONasMWi: { opinia, tytu },
  } = useStaticQuery(graphql`
    query opinionQuery {
      datoCmsCoONasMWi {
        tytu
        opinia {
          tekst
          autor
          id
        }
      }
    }
  `);
  const slider = useRef(null);

  const settings = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    infinite: true,
  };

  return (
    <StyledOpinions>
      <StyledText
        hasdeclaredfontsize="clamp(22px, 2.292vw, 44px)"
        hasdeclaredpadding="0 0 clamp(18px, 1.979vw, 32px) 0"
        hasdeclaredtextalign="center"
        hasdeclaredfontweight="700"
      >
        {tytu}
      </StyledText>
      <Slider ref={slider} {...settings}>
        {opinia.map(({ id, autor, tekst }) => (
          <OpinionCard key={id} author={autor} opinion={tekst} />
        ))}
      </Slider>
    </StyledOpinions>
  );
};

export default Opinions;
