/** @format */

import styled from "styled-components";
import { Link } from "gatsby";

export const StyledLink = styled(Link)`
  border-radius: 64px;
  transition: background-color 400ms, color 400ms, border 400ms;
  cursor: pointer;
  text-transform: uppercase;
  font: 700 clamp(14px, 0.938vw, 18px) Poppins;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #fff;
  background-color: #0bc76d;
  text-align: center;
  margin: 0 auto;
  padding: clamp(12px, 1.042vw, 20px) clamp(14px, 1.146vw, 22px);
  transition: opacity 250ms linear;

  &:hover {
    opacity: 0.8;
  }

  &:focus-visible {
    outline-width: 1px;
    outline-style: solid;
    outline-color: #0bc76d;
    outline-offset: 4px;
  }

  &.realization-card-btn {
    width: 100%;
    max-width: 300px;
    background-color: transparent;
    color: #745239;
    font: 700 clamp(14px, 1.138vw, 20px) Poppins;
    margin-top: 10px;
    border: 1px solid #745239;
  }

  &.realization-content {
    max-width: 277px;
    height: 71px;
    font: 700 clamp(16px, 1.138vw, 20px) Poppins;
    margin-top: clamp(40px, 3.425vw, 80px);
  }

  &.home-second-section {
    border: none;
    margin: 30px 0 0 0;
    padding: clamp(12px, 1.042vw, 20px) clamp(24px, 2.46vw, 38px);

    @media only screen and (max-width: 480px) {
      width: 100%;
      min-width: unset;
    }
  }

  &.about-us-btn {
    margin: 0;
    min-width: 273px;

    @media only screen and (max-width: 480px) {
      width: 100%;
      min-width: unset;
    }
  }

  &.home-third-section {
    margin-top: clamp(40px, 3.425vw, 60px);
    min-width: 320px;

    @media only screen and (max-width: 480px) {
      width: 100%;
      min-width: unset;
    }
  }
`;

export const StyledButton = styled.button`
  border-radius: 64px;
  transition: background-color 400ms, color 400ms;
  cursor: pointer;
  text-transform: uppercase;
  font: 700 clamp(14px, 0.938vw, 18px) Poppins;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #fff;
  background-color: #0bc76d;
  text-align: center;
  margin: 0 auto;
  padding: clamp(12px, 1.042vw, 20px) clamp(14px, 1.146vw, 22px);

  &:focus-visible {
    outline-width: 1px;
    outline-style: solid;
    outline-color: #0bc76d;
    outline-offset: 4px;
  }

  &.realization-card-btn {
    width: 100%;
    max-width: clamp(240px, 15.625vw, 300px);
    background-color: transparent;
    color: #745239;
    font: 700 clamp(14px, 1.138vw, 17px) Poppins;
    margin-top: 10px;
    border: 1px solid #745239;
    padding: clamp(8px, 0.942vw, 16px) clamp(8px, 0.942vw, 16px);

    &:hover {
      background-color: #745239;
      color: #fff;
    }
  }
`;
