import styled from "styled-components";

export const StyledOfferSecondSection = styled.div`
  width: 100%;
  background-color: #faf7f1;
  display: flex;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const StyledLeftWrapper = styled.div`
  width: 50%;
  padding: 82px clamp(22px, 5.469vw, 108px) clamp(64px, 5.333vw, 58px)
    clamp(22px, 9.583vw, 184px);

  @media only screen and (max-width: 992px) {
    padding-left: 22px;
    padding-right: 22px;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-top: 0;
  }
`;

export const StyledRightWrapper = styled.div`
  width: 50%;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 82px clamp(22px, 9.583vw, 184px) clamp(64px, 5.333vw, 58px)
    clamp(22px, 5.469vw, 108px);

  @media only screen and (max-width: 992px) {
    padding-left: 22px;
    padding-right: 22px;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-top: 0;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  gap: clamp(18px, 1.563vw, 30px);
  flex-direction: column;
  padding-top: ${({ largepadding }) => (largepadding ? "132px" : "60px")};
  font-size: clamp(16px, 1.05vw, 20px);
  line-height: 1.35em;

  .gatsby-image-wrapper {
    height: 350px;
  }
`;

export const StyledBottomText = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 82px clamp(22px, 9.583vw, 184px) clamp(64px, 5.333vw, 58px)
    clamp(22px, 9.583vw, 184px);

  @media only screen and (max-width: 992px) {
    padding-left: 22px;
    padding-right: 22px;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding-top: 64px;
  }
`;

export const StyledLeftBottomWrapper = styled.div`
  display: flex;
  gap: clamp(18px, 1.563vw, 30px);
  flex-direction: column;
  width: 50%;
  padding-right: clamp(22px, 5.469vw, 108px);
  font-size: clamp(16px, 1.05vw, 20px);
  line-height: 1.35em;

  @media only screen and (max-width: 992px) {
    padding-left: 22px;
    padding-right: 22px;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

export const StyledBottomRightWrapper = styled.div`
  width: 50%;
  padding-left: clamp(22px, 5.469vw, 108px);

  @media only screen and (max-width: 992px) {
    padding-left: 22px;
    padding-right: 22px;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 20px 0 0 0;

    .gatsby-image-wrapper {
      height: 350px;
    }
  }
`;
