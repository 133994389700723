import styled from "styled-components";

export const StyledOpinions = styled.div`
  width: 100%;
  background-color: #faf7f1;
  padding: 44px clamp(22px, 9.583vw, 184px) clamp(64px, 5.333vw, 98px);

  .slick-track {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .slick-slide {
    height: 100%;
    flex: 1;
  }

  .slick-dots {
    bottom: -40px;

    li {
      width: clamp(18px, 1.563vw, 30px);
      height: clamp(18px, 1.563vw, 30px);
      margin-right: clamp(12px, 1.563vw, 30px);

      &:last-child {
        margin-right: 0;
      }

      button {
        width: 100%;
        height: 100%;

        &:before {
          width: 100%;
          height: 100%;
          background-color: #745239;
          border-radius: 50%;
          content: "";
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    padding-left: 22px;
    padding-right: 22px;
  }
`;
