/** @format */

import React from "react";

import Image from "../../atoms/Image/Image";

import { StyledBanner, StyledVideoBanner } from "./StyledBanner";
import { StyledText } from "../../atoms/StyledText/StyledText";

import video from "../../../../static/videos/hala1080.mp4";

const Banner = ({ image, text, disableBg, isVideo, hasHeight = true }) => {
  return (
    <StyledBanner disablebg={disableBg} hasheight={hasHeight}>
      {isVideo ? (
        <StyledVideoBanner>
          <video width="100%" height="100%" autoPlay muted loop>
            <source src={video} type="video/mp4" />
          </video>
        </StyledVideoBanner>
      ) : (
        <>{image ? <Image image={image} /> : null}</>
      )}
      <StyledText
        hasdeclaredfontcolor="#fff"
        hasdeclaredfontsize="clamp(32px, 2.788vw, 56px)"
        hasdeclaredfontweight="700"
      >
        {text}
      </StyledText>
    </StyledBanner>
  );
};

export default Banner;
