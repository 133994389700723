/** @format */

import React from "react";

import { StyledButton, StyledLink } from "./StyledButton";

const Button = ({
  text,
  ariaLabel,
  variant,
  className,
  tabIndex,
  disabled,
  where,
}) => {
  return !where ? (
    <StyledButton
      aria-label={ariaLabel}
      className={className}
      tabIndex={tabIndex}
      variant={variant}
    >
      {text}
    </StyledButton>
  ) : (
    <StyledLink
      to={where}
      disabled={disabled}
      className={className}
      tabIndex={tabIndex}
      aria-label={ariaLabel}
      variant={variant}
    >
      {text}
    </StyledLink>
  );
};

export default Button;
