import styled from "styled-components";

export const StyledBanner = styled.div`
  height: ${({ hasheight }) => (hasheight ? "600px" : "unset")};
  width: 100%;
  position: relative;

  .gatsby-image-wrapper,
  img {
    width: 100%;
    height: 100%;
  }

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: ${({ disablebg }) => (disablebg ? "none" : "block")};
  }

  p {
    position: absolute;
    left: clamp(22px, 9.583vw, 184px);
    top: 50%;
    transform: translateY(-50%);
    text-transform: uppercase;
    z-index: 2;
  }

  @media only screen and (max-width: 768px) {
    height: ${({ hasheight }) => (hasheight ? "260px" : "unset")};
  }
`;

export const StyledVideoBanner = styled.div`
  width: 100%;
  height: 100%;
  margin-top: -100px;
  z-index: -1;
  position: relative;

  @media only screen and (max-width: 992px) {
    margin-top: 0;
  }
`;
