/** @format */

import styled from "styled-components";

export const StyledOurRealizations = styled.div`
  width: 100%;
  padding: 44px clamp(22px, 9.583vw, 184px) clamp(64px, 5.333vw, 98px);
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  @media only screen and (max-width: 992px) {
    padding-left: 22px;
    padding-right: 22px;
  }
`;

export const StyledContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 40px;

  @media only screen and (max-width: 1216px) {
    gap: 20px;
    justify-content: center;

    > a {
      &:nth-child(3) {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 699px) {
    flex-direction: column;
    align-items: center;
  }
`;
