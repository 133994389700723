/** @format */

import React from "react";

import ParseText from "../../atoms/ParseText/ParseText";

import { StyledOpinionCard, StyledTextWrapper, StyledAuthor } from "./StyledOpinionCard";
import { StyledText } from "../../atoms/StyledText/StyledText";

const OpinionCard = ({ author, opinion }) => {
  return (
    <StyledOpinionCard>
      <StyledTextWrapper>
        <ParseText text={opinion} />
      </StyledTextWrapper>
      <StyledAuthor>
        <StyledText>{author}</StyledText>
      </StyledAuthor>
    </StyledOpinionCard>
  );
};

export default OpinionCard;
